import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import ArrowLeft2 from '../../../assets/icons/arrow-left-2';

import './back-button.css';

type BackButtonProps = {
  readonly prevUrl: string;
};

function BackButton({ prevUrl }: BackButtonProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Link className='back-button-container' to={prevUrl}>
      <ArrowLeft2 />
      <span>{t('buttons.back')}</span>
    </Link>
  );
}

BackButton.displayName = 'BackButton';

export default BackButton;
